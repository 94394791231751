import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const defaultFormat = 'YYYY-MM-DD'
const defaultFormat2 = 'YYYY-MM-DD HH:mm:ss'

// utc转对应时区的时间
export function dateTimeFormat(date, zone, format) {
  if (!date) {
    return ''
  }
  const formats = format || defaultFormat
  return dayjs.utc(date).tz(zone).format(formats)
}

// 日期直接格式化
export function dateFormat(date, format) {
  if (!date) {
    return ''
  }
  const formats = format || defaultFormat2
  return dayjs(date).format(formats)
}
