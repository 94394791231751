<template>
  <div class="chat-conatiner" v-loading="dataListLoading">
    <div v-if="this.searchForm.recipient">
      <div v-for="item in dataList" :key="item.id" class="chat-box">
        <div class="left-auth-box">
          <div class="left-auth-box-img" v-if="item.storeHeadImg">
            <img lazy :src="item.storeHeadImg" class="left-auth-elimg"/>
          </div>
          <div class="left-auth-box-img" v-else></div>
          <div class="left-auth-box-title">
            <div class="left-auth-titname">{{ item.storeName }}</div>
          <div style="font-size: 14px; margin-right:10px;">{{ item.releaseTime | dateFilter }}</div>
          </div>
        </div>
        <div class="left-info-box" @click="openNewLink(item.releaseUrl)">
          <div v-if="item.img" class="left-info-img">
            <el-image lazy :src="item.img" style="width: 360px;" />
          </div>
          <div v-if="item.video" class="left-info-img">
            <!-- <iframe :src="youtubeUrl+item.video" style="width:360px;height:192px" frameborder="0"></iframe> -->
          </div>
          <div v-if="item.content" class="content-info" v-html="doBr(item.content)" />
        </div>
      </div>
    </div>
    <div v-if="!dataList || dataList.length == 0" class="no-data">{{ $t('暂无数据') }}</div>
    <!-- <div v-if="noData" style="text-align: center;margin-bottom: 10px;color: #cac6c6;">{{ $t('暂无更多数据') }}</div> -->
  </div>
</template>

<script>
import { getMarketing } from '@/api/store'
import { dateFormat } from '@/utils/date'
let dataArr =[]
export default {
  filters: {
    dateFilter(val) {
      return dateFormat(val)
    },
  },
  data() {
    return {
       searchForm: {
        page: 1,
        pageSize: 20,
        recipient:1
      },
      totalSize: 0,
      dataList: [],
      dataListLoading: false,
      noData:false,
      youtubeUrl: 'https://www.youtube.com/embed/',
    }
  },
  created() {
    this.init()
  },
  computed: {
    lan() {
      return localStorage.getItem('locale') || 'ja'
    },
  },
  mounted(){
    window.addEventListener('scroll', this.showChange)
  },
  methods: {
    showChange(e){
      // 滚动条与页面高度
      let scrollTop = document.documentElement.scrollTop
      // 可视区域高度
      let curHeight = document.documentElement.clientHeight
      // 页面总高度
      let totalHeight = document.body.scrollHeight
      if(scrollTop + curHeight >= totalHeight){
        // 滚到底了
        this.searchForm.page ++
        this.init()
      }
    },
    init() {
    this.dataListLoading = true
     let PageDataTotal=0
     let isTop =1
      this.searchForm.lan=this.lan
      getMarketing(this.searchForm,isTop).then((response) => {
        if (response && response.data) {
         this.searchForm.page===1? this.dataList=response.data.list:''
        //  this.isTop ===1 ? this.dataList=response.data.list :''
          this.totalSize = response.data.totalSize
          PageDataTotal =Math.ceil((this.totalSize+(this.searchForm.pageSize-1))/this.searchForm.pageSize) //获取有数据的总页数          
          this.dataListLoading = false
          if(this.searchForm.page!==1 && this.searchForm.page<=PageDataTotal){
          dataArr = this.dataList.concat(response.data.list)
          this.dataList=dataArr
           if(isTop){
             this.dataList=dataArr
              this.dataList.sort((a,b)=>{
              return b.releaseTime < a.releaseTime ? -1 : 1
            })
           }
          }else if (this.searchForm.page>=PageDataTotal){
            this.noData = true
            // this.$message({
              //   message:"没有更多数据,已到底(>_<)",
            //   type: 'warning',
            //   duration:1500
            // })
          }
          console.log(this.dataList);
        }
      })
    },
    // 打开链接
    openNewLink(link) {
      if (link && link.startsWith('http')) {
        window.open(link, '_blank')
      }
    },
    doBr(str) {
      if (str) {
        return str.replace(/\n/g, '<br>')
      }
      return ''
    },
  },
}
</script>
<style lang='scss' scoped>
@media only screen and (max-width: 320px) {
  iframe{
    width: 280px !important;
  }
  .left-auth-titname{
         width: 225px;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
      }
}
.chat-box {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .left-auth-box {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    .left-auth-box-img{
      border-radius: 50%;
      background:rgb(196, 194, 194); 
      // margin-right:10px;
      width: 45px;
      height: 45px;
      .left-auth-elimg{
        width:100%;
        height:100%;
        overflow:hidden;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .left-auth-box-title{
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left:10px;

      .left-auth-titname{
        // width: 160px;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
      }
    }
    div {
      color: #606266;
      line-height: 20px;
    }
  }
  .left-info-box {
    padding: 10px;
    width: 100%;
    border-radius: 15px;
    box-sizing: border-box;
    background: #fff;
    table-layout: fixed;
    .content-info {
      font-size: 14px;
      line-height: 20px;
      color: #000;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
    }
    .left-info-img {
      display: flex;
      justify-content: center;
    }
  }
}
.chat-conatiner {
  width: 100%;
  min-height: 80vh;
  overflow: hidden;
  background: rgb(242, 242, 242);
}
.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
</style>
